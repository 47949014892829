
import { reactive } from 'vue'

export default {
	components: { },
	setup(props, context) {
		return {
			view: reactive({
				checkImage: ''
			}),
		}
	},
	mounted() {
		this.download()
	},
	methods: {
		//下载
		download: function(isDownload){
			if(isDownload) {
				return this._download('/Order/Order/myCode', { isDownload: true }, null, '核销码.png')
			}
			this._post('/Order/Order/myCode', { isDownload: isDownload?true:false}, (res)=>{
				this.view.checkImage = res.data.url
				this.$forceUpdate()
			})
		}
	}
}
